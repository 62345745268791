import { envConfig } from '@twisto/base/env-config'

export const getHeaders = ({
  language,
  region,
}: {
  language: GLOBALS.Language
  region: GLOBALS.Region
}) => ({
  'Accept-Language': language,
  'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  // Region header is required for federated GraphQL endpoint
  Region: region,
  ...(envConfig.MOCK_API
    ? {
        'X-Referer': window.location.href,
      }
    : {}),
})
