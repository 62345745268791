import type { ReactNode } from 'react'

import { isEmpty, pickBy } from 'ramda'

import { useBreakpoint } from '@twisto/hooks'
import type { BreakpointVariants } from '@twisto/styles'
import type { RequireAtLeastOne } from '@twisto/utils'

type HiddenBreakpointProps = RequireAtLeastOne<
  Partial<Record<BreakpointVariants, boolean>>
>

type Props = {
  children: ReactNode
} & HiddenBreakpointProps

export const Hidden = ({ children, ...other }: Props) => {
  const hiddenBreakpointProps = pickBy<typeof other, typeof other>(
    (value) => Boolean(value),
    other
  )

  if (Object.keys(hiddenBreakpointProps).length > 1) {
    throw new Error('Multiple HiddenBreakpoint props used')
  }

  // when there is no hiddenBreakpointProp, don't hide
  const usedBreakpointProp = !isEmpty(hiddenBreakpointProps)
    ? (Object.keys(hiddenBreakpointProps)[0] as keyof HiddenBreakpointProps)
    : 'xsDown'

  const isHidden = useBreakpoint(usedBreakpointProp)

  return <>{isHidden ? null : children}</>
}
