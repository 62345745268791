/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { RefObject } from 'react'
import { useEffect, useState } from 'react'

import { isNil } from 'ramda'

type ArrowOrientation =
  | 'upStart'
  | 'upCenter'
  | 'upEnd'
  | 'downStart'
  | 'downCenter'
  | 'downEnd'
  | 'sideStart'
  | 'sideEnd'

type UseTooltipPositionArgs = {
  triggerElementRef: RefObject<HTMLElement>
  tooltipElementRef: RefObject<HTMLElement>
  isVisible: boolean
}

export const useTooltipPosition = ({
  triggerElementRef,
  tooltipElementRef,
  isVisible,
}: UseTooltipPositionArgs) => {
  const [tooltipXPosition, setTooltipXPosition] = useState<number | undefined>(
    undefined
  )
  const [tooltipYPosition, setTooltipYPosition] = useState<number | undefined>(
    undefined
  )
  const [arrowOrientation, setArrowOrientation] = useState<
    ArrowOrientation | undefined
  >(undefined)

  useEffect(() => {
    if (isVisible) {
      const handleTooltipPosition = () => {
        if (triggerElementRef.current && tooltipElementRef.current) {
          const triggerElementRect =
            triggerElementRef.current.getBoundingClientRect()

          const tooltipElementRect =
            tooltipElementRef.current.getBoundingClientRect()

          /*
          Find tooltip X and Y position based on trigger element position in the viewport and tooltip element size:

          1.  if there is a space at the top of the trigger element which is equal or bigger than tooltip height,
            A.  and the tooltip width is equal or smaller than the trigger element width,
                or the tooltip width is bigger than the trigger element width,
                but there is enough space on the left and on the right side of the trigger element
                which is equal or bigger than (tooltip element width - trigger element width) / 2,
                place the tooltip at the top of the trigger element and align it with the center of the trigger element
            B.  and the tooltip width is bigger than the trigger element width,
                but there is not enough space on the left side of the trigger element
                to place the tooltip at the top center ((tooltip element width - trigger element width) / 2),
                place the tooltip at the top and align it with the beginning of the trigger element
            C.  and the tooltip width is bigger than the trigger element width,
                but there is not enough space on the right side of the trigger element
                to place the tooltip at the top center ((tooltip element width - trigger element width) / 2),
                place the tooltip at the top and align it with the end of the trigger element

          2.  if there is less space at the top of the trigger element than tooltip height,
              but there is enough space on the right side of the trigger element
              which is equal or bigger than tooltip width,
              place the tooltip on the right side of the trigger element
              and align it with the center of the trigger element

          3.  if there is less space at the top of the trigger element than tooltip height,
              and there less space on the right side of the trigger element than tooltip width,
              but there is enough space at the bottom of the trigger element
              which is equal or bigger than tooltip height,
            A.  and the tooltip width is equal or smaller than the trigger element width,
                or the tooltip width is bigger than the trigger element width,
                but there is enough space on the left and on the right side of the trigger element
                which is equal or bigger than (tooltip element width - trigger element width) / 2,
                place the tooltip at the bottom of the trigger element and align it with the center of the trigger element
            B.  and the tooltip width is bigger than the trigger element width,
                but there is not enough space on the left side of the trigger element
                to place the tooltip at the bottom center ((tooltip element width - trigger element width) / 2),
                place the tooltip at the bottom and align it with the beginning of the trigger element
            C.  and the tooltip width is bigger than the trigger element width,
                but there is not enough space on the right side of the trigger element
                to place the tooltip at the bottom center ((tooltip element width - trigger element width) / 2),
                place the tooltip at the bottom and align it with the end of the trigger element

          4.  if there is less space at the top of the trigger element than tooltip height,
              and there less space on the right side of the trigger element than tooltip width,
              and there is less space at the bottom of the trigger element than tooltip height,
              but there is enough space on the left side of the trigger element
              which is equal or bigger than tooltip width,
              place the tooltip on the left side of the trigger element

          */

          let tooltipXPosition = 0
          let tooltipYPosition = 0
          let arrowOrientation: ArrowOrientation | undefined

          // 1.A.
          if (
            triggerElementRect.y >= tooltipElementRect.height &&
            (tooltipElementRect.width <= triggerElementRect.width ||
              (tooltipElementRect.width > triggerElementRect.width &&
                triggerElementRect.x >=
                  (tooltipElementRect.width - triggerElementRect.width) / 2 &&
                window.innerWidth - triggerElementRect.right >=
                  (tooltipElementRect.width - triggerElementRect.width) / 2))
          ) {
            tooltipXPosition =
              triggerElementRect.x +
              triggerElementRect.width / 2 -
              tooltipElementRect.width / 2
            tooltipYPosition = triggerElementRect.y - tooltipElementRect.height
            arrowOrientation = 'downCenter'
          }

          // 1.B.
          if (
            triggerElementRect.y >= tooltipElementRect.height &&
            tooltipElementRect.width > triggerElementRect.width &&
            triggerElementRect.x <
              (tooltipElementRect.width - triggerElementRect.width) / 2
          ) {
            tooltipXPosition = triggerElementRect.x
            tooltipYPosition = triggerElementRect.y - tooltipElementRect.height
            arrowOrientation = 'downStart'
          }

          // 1.C.
          if (
            triggerElementRect.y >= tooltipElementRect.height &&
            tooltipElementRect.width > triggerElementRect.width &&
            window.innerWidth - triggerElementRect.right <
              (tooltipElementRect.width - triggerElementRect.width) / 2
          ) {
            tooltipXPosition =
              triggerElementRect.right - tooltipElementRect.width
            tooltipYPosition = triggerElementRect.y - tooltipElementRect.height
            arrowOrientation = 'downEnd'
          }

          // 2.
          if (
            triggerElementRect.y < tooltipElementRect.height &&
            window.innerWidth - triggerElementRect.right >=
              tooltipElementRect.width
          ) {
            tooltipXPosition = triggerElementRect.right
            tooltipYPosition =
              triggerElementRect.y +
              triggerElementRect.height / 2 -
              tooltipElementRect.height / 2
            arrowOrientation = 'sideStart'
          }

          // 3.A.
          if (
            triggerElementRect.y < tooltipElementRect.height &&
            window.innerWidth - triggerElementRect.right <
              tooltipElementRect.width &&
            window.innerHeight - triggerElementRect.bottom >=
              tooltipElementRect.height &&
            (tooltipElementRect.width <= triggerElementRect.width ||
              (tooltipElementRect.width > triggerElementRect.width &&
                triggerElementRect.x >=
                  (tooltipElementRect.width - triggerElementRect.width) / 2 &&
                window.innerWidth - triggerElementRect.right >=
                  (tooltipElementRect.width - triggerElementRect.width) / 2))
          ) {
            tooltipXPosition =
              triggerElementRect.x +
              triggerElementRect.width / 2 -
              tooltipElementRect.width / 2
            tooltipYPosition = triggerElementRect.bottom
            arrowOrientation = 'upCenter'
          }

          // 3.B.
          if (
            triggerElementRect.y < tooltipElementRect.height &&
            window.innerWidth - triggerElementRect.right <
              tooltipElementRect.width &&
            window.innerHeight - triggerElementRect.bottom >=
              tooltipElementRect.height &&
            tooltipElementRect.width > triggerElementRect.width &&
            triggerElementRect.x <
              (tooltipElementRect.width - triggerElementRect.width) / 2
          ) {
            tooltipXPosition = triggerElementRect.x
            tooltipYPosition = triggerElementRect.bottom
            arrowOrientation = 'upStart'
          }

          // 3.C.
          if (
            triggerElementRect.y < tooltipElementRect.height &&
            window.innerWidth - triggerElementRect.right <
              tooltipElementRect.width &&
            window.innerHeight - triggerElementRect.bottom >=
              tooltipElementRect.height &&
            tooltipElementRect.width > triggerElementRect.width &&
            window.innerWidth - triggerElementRect.right <
              (tooltipElementRect.width - triggerElementRect.width) / 2
          ) {
            tooltipXPosition =
              triggerElementRect.right - tooltipElementRect.width
            tooltipYPosition = triggerElementRect.bottom
            arrowOrientation = 'upEnd'
          }

          // 4.
          if (
            triggerElementRect.y < tooltipElementRect.height &&
            window.innerWidth - triggerElementRect.right <
              tooltipElementRect.width &&
            window.innerHeight - triggerElementRect.bottom <
              tooltipElementRect.height &&
            triggerElementRect.x >= tooltipElementRect.width
          ) {
            tooltipXPosition = triggerElementRect.x - tooltipElementRect.width
            tooltipYPosition =
              triggerElementRect.y +
              triggerElementRect.height / 2 -
              tooltipElementRect.height / 2
            arrowOrientation = 'sideEnd'
          }

          setTooltipXPosition(tooltipXPosition)
          setTooltipYPosition(tooltipYPosition)
          setArrowOrientation(arrowOrientation)
        }
      }

      handleTooltipPosition()

      window.addEventListener('resize', handleTooltipPosition)
      window.addEventListener('scroll', handleTooltipPosition)

      return () => {
        window.removeEventListener('resize', handleTooltipPosition)
        window.removeEventListener('scroll', handleTooltipPosition)
      }
    }
  }, [isVisible, tooltipElementRef, triggerElementRef])

  return isNil(tooltipXPosition) &&
    isNil(tooltipYPosition) &&
    isNil(arrowOrientation)
    ? undefined
    : {
        x: tooltipXPosition,
        y: tooltipYPosition,
        arrowOrientation,
      }
}
