import { partial } from 'ramda'

import { joinPaths, prependPathToRoutes } from '@twisto/utils'

const basepath = '/verification/'

const basicInfoSection = partial(joinPaths, ['/basic-info/'])

const basicInfo = {
  index: basicInfoSection('/'),
  nationality: basicInfoSection('/nationality/'),
  address: basicInfoSection('/address/'),
  personalId: basicInfoSection('/personal-id/'),
  phone: basicInfoSection('/phone/'),
  failure: '/failure/',
} as const

const additionalInfoSection = partial(joinPaths, ['/additional-info/'])

const additionalInfo = {
  index: additionalInfoSection('/'),
  educationType: additionalInfoSection('/educational-attainment/'),
  familyType: additionalInfoSection('/marital-status/'),
  familyChildren: additionalInfoSection('/children-count/'),
  incomeType: additionalInfoSection('/income-source/'),
  incomeAmount: additionalInfoSection('/net-income/'),
  expenseAmount: additionalInfoSection('/monthly-expenses/'),
  expenseDebt: additionalInfoSection('/loan-repayments/'),
  desiredLimit: additionalInfoSection('/credit-amount/'),
} as const

const pricingPlanSection = partial(joinPaths, ['/pricing-plan/'])

const pricingPlan = {
  index: pricingPlanSection('/'),
  planOptions: pricingPlanSection('/plan/'),
} as const

const identitySection = partial(joinPaths, ['/identity/'])

const identity = {
  index: identitySection('/'),
} as const

const summarySection = partial(joinPaths, ['/summary/'])
const summaryEdit = partial(joinPaths, [summarySection('/edit/')])

const summary = {
  index: summarySection('/'),
  name: summaryEdit('/name/'),
  address: summaryEdit('/address/'),
  personalId: summaryEdit('/personal-id/'),
  phoneNumber: summaryEdit('/phone/'),
  educationType: summaryEdit('/educational-attainment/'),
  familyType: summaryEdit('/marital-status/'),
  familyChildren: summaryEdit('/children-count/'),
  incomeType: summaryEdit('/income-source/'),
  incomeAmount: summaryEdit('/net-income/'),
  expenseAmount: summaryEdit('/monthly-expenses/'),
  expenseDebt: summaryEdit('/loan-repayments/'),
  desiredLimit: summaryEdit('/credit-amount/'),
  planOptions: summaryEdit('/plan/'),
  nationality: summaryEdit('/nationality'),
} as const

const signContractSection = partial(joinPaths, ['/contract-sign/'])

const signContract = {
  index: signContractSection('/'),
  smsSign: signContractSection('/sms-sign/'),
} as const

const intro = '/intro/'
const consent = '/consent/'
const complete = '/complete/'
const lastStep = '/last-step/'

const routesDefinition = {
  index: {
    path: '/',
    next: consent,
  },
  consent: {
    path: consent,
    next: intro,
  },
  intro: {
    path: intro,
    previous: consent,
    next: pricingPlan.index,
  },
  pricingPlan: {
    index: {
      previous: intro,
      path: pricingPlan.index,
      next: pricingPlan.planOptions,
      step: 0,
    },
    planOptions: {
      previous: pricingPlan.index,
      path: pricingPlan.planOptions,
      next: basicInfo.index,
      step: 1,
      hideStepper: true,
    },
  },
  basicInfo: {
    index: {
      path: basicInfo.index,
      previous: pricingPlan.planOptions,
      next: basicInfo.nationality,
      step: 0,
    },

    nationality: {
      path: basicInfo.nationality,
      previous: basicInfo.index,
      next: basicInfo.address,
      step: 1,
    },
    address: {
      path: basicInfo.address,
      previous: basicInfo.nationality,
      next: basicInfo.personalId,
      step: 2,
    },
    personalId: {
      path: basicInfo.personalId,
      previous: basicInfo.address,
      next: basicInfo.phone,
      step: 3,
    },
    phone: {
      path: basicInfo.phone,
      previous: basicInfo.personalId,
      next: additionalInfo.index,
      step: 4,
    },
    failure: { path: basicInfo.failure },
  },
  additionalInfo: {
    index: {
      previous: basicInfo.phone,
      path: additionalInfo.index,
      next: additionalInfo.educationType,
      step: 0,
    },
    educationType: {
      previous: additionalInfo.index,
      path: additionalInfo.educationType,
      next: additionalInfo.familyType,
      step: 1,
    },
    familyType: {
      previous: additionalInfo.educationType,
      path: additionalInfo.familyType,
      next: additionalInfo.familyChildren,
      step: 2,
    },
    familyChildren: {
      previous: additionalInfo.familyType,
      path: additionalInfo.familyChildren,
      next: additionalInfo.incomeType,
      step: 3,
    },
    incomeType: {
      previous: additionalInfo.familyChildren,
      path: additionalInfo.incomeType,
      next: additionalInfo.incomeAmount,
      step: 4,
    },
    incomeAmount: {
      previous: additionalInfo.incomeType,
      path: additionalInfo.incomeAmount,
      next: additionalInfo.expenseAmount,
      step: 5,
    },
    expenseAmount: {
      previous: additionalInfo.incomeAmount,
      path: additionalInfo.expenseAmount,
      next: additionalInfo.expenseDebt,
      step: 6,
    },
    expenseDebt: {
      previous: additionalInfo.expenseAmount,
      path: additionalInfo.expenseDebt,
      next: additionalInfo.desiredLimit,
      step: 7,
    },
    desiredLimit: {
      previous: additionalInfo.expenseDebt,
      path: additionalInfo.desiredLimit,
      next: identity.index,
      step: 8,
    },
  },
  identity: {
    index: {
      previous: additionalInfo.desiredLimit,
      path: identity.index,
      next: summary.index,
      step: 0,
    },
  },
  summary: {
    index: {
      path: summary.index,
      next: signContract.index,
    },
    address: {
      previous: summary.index,
      path: summary.address,
    },

    nationality: {
      previous: summary.index,
      path: summary.nationality,
    },
    personalId: {
      previous: summary.index,
      path: summary.personalId,
    },
    phoneNumber: {
      previous: summary.index,
      path: summary.phoneNumber,
    },
    educationType: {
      previous: summary.index,
      path: summary.educationType,
    },
    familyType: {
      previous: summary.index,
      path: summary.familyType,
    },
    familyChildren: {
      previous: summary.index,
      path: summary.familyChildren,
    },
    incomeType: {
      previous: summary.index,
      path: summary.incomeType,
    },
    incomeAmount: {
      previous: summary.index,
      path: summary.incomeAmount,
    },
    expenseAmount: {
      previous: summary.index,
      path: summary.expenseAmount,
    },
    expenseDebt: {
      previous: summary.index,
      path: summary.expenseDebt,
    },
    desiredLimit: {
      previous: summary.index,
      path: summary.desiredLimit,
    },
    planOptions: {
      previous: summary.index,
      path: summary.planOptions,
    },
  },
  signContract: {
    index: {
      previous: summary.index,
      path: signContract.index,
      next: signContract.smsSign,
    },
    smsSign: {
      previous: signContract.index,
      path: signContract.smsSign,
      next: complete,
    },
  },
  complete: {
    path: complete,
  },
  lastStep: {
    path: lastStep,
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)
