import type { ErrorResponse } from '@apollo/client/link/error'
import { isNil } from 'ramda'

import { networkStatusCode } from '@twisto/api-common'

import {
  showDefaultError,
  showNoConnectionError,
  showRequestError,
  showServerError,
  showTimeoutError,
} from './error-modal-builder'

export const parseApolloError = ({ networkError }: ErrorResponse) =>
  parseStatus(
    networkError && 'statusCode' in networkError
      ? networkError.statusCode
      : undefined
  )

export const parseStatus = (statusCode: number | undefined) => {
  if (isNil(statusCode) && !window.navigator.onLine) {
    return showNoConnectionError()
  }

  if (isNil(statusCode)) {
    return showDefaultError()
  }

  if (statusCode === networkStatusCode.timeout) {
    return showTimeoutError()
  }

  if (
    statusCode >= networkStatusCode.badRequest &&
    statusCode < networkStatusCode.internalServerError
  ) {
    return showRequestError()
  }

  return showServerError()
}
