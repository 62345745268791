import { useAtom } from 'jotai'

// eslint-disable-next-line no-restricted-imports
import { useAppConfig } from '@twisto/base'
import {
  customerConfigObjects,
  docsObjects,
  urlsObjects,
} from '@twisto/base/region'

import { remoteFeatureFlagsAtom } from './remote-feature-flags-loader'
import { remoteSectionsAvailabilityAtom } from './remote-sections-availability-loader'

export type CustomerConfig = ReturnType<typeof useCustomerConfig>

export const useCustomerConfig = () => {
  const baseConfig = useAppConfig()
  const [remoteFeatureFlags] = useAtom(remoteFeatureFlagsAtom)
  const [remoteSectionsAvailability] = useAtom(remoteSectionsAvailabilityAtom)
  const { region } = baseConfig

  const mergedConfig = {
    ...baseConfig,
    urls: urlsObjects[region],
    docs: docsObjects[region],
    ...customerConfigObjects[region],
    featureFlags: {
      ...baseConfig.featureFlags,
      ...remoteFeatureFlags,
    },
    sectionsAvailability: {
      ...remoteSectionsAvailability,
    },
  }

  return mergedConfig
}
