import { i18n } from '@lingui/core'
import Cleave from 'cleave.js'
import type { CleaveOptions } from 'cleave.js/options'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

const DEFAULT_FRACTION_DIGITS = 2

type SupportedLangs = GLOBALS.Language

export const formatNumber =
  (
    lang: SupportedLangs,
    {
      minimumFractionDigits = DEFAULT_FRACTION_DIGITS,
      ...options
    }: Intl.NumberFormatOptions & {
      style?: 'decimal' | 'percent' | 'currency' | 'unit'
    }
  ) =>
  (value: string | number) =>
    Intl.NumberFormat(lang, {
      minimumFractionDigits,
      maximumFractionDigits: DEFAULT_FRACTION_DIGITS,
      ...options,
    }).format(Number(value))

export const formatCurrencyNumber =
  (
    lang: SupportedLangs,
    options: Omit<Parameters<typeof formatNumber>[1], 'currencyDisplay'>
  ) =>
  (value: number | string) =>
    formatNumber(lang, {
      ...options,
      style: options.style || 'currency',
      currencyDisplay: 'symbol',
    })(value)

export const formatPhoneNumber =
  (formatOptions: CleaveOptions) => (phoneNumber: string) => {
    const input = document.createElement('input')
    const cleave = new Cleave(input, formatOptions)
    cleave.setRawValue(phoneNumber)

    return cleave.getFormattedValue()
  }

export type CurrencyPosition = 'start' | 'end'

export const parseFormattedCurrencyNumber = (
  value: string
): { currency: string; position: CurrencyPosition } => {
  const parts = new RegExp(/[^\d.,\-\s]+/).exec(value) as RegExpExecArray

  return {
    currency: parts[0],
    position: parts.index ? 'end' : 'start',
  }
}

export const getFormattedDate = (
  date: Date | string,
  lang: SupportedLangs,
  options: Intl.DateTimeFormatOptions
) => new Date(date).toLocaleString(lang, options)

export const getMaskFormattedDate = (
  date: Date | string,
  formatString?: string
) => dayjs(date).format(formatString)

export const getCountedDateFromDate = (
  date: Date,
  days: number,
  lang: SupportedLangs,
  options: Intl.DateTimeFormatOptions
) =>
  getFormattedDate(
    dayjs(date.setDate(date.getDate() + days)).format(),
    lang,
    options
  )

export const getCalendarDateAndTime = (
  date: Date | string,
  lang: SupportedLangs
) => {
  const formattedDate = getFormattedDate(date, lang, {
    day: 'numeric',
    month: 'numeric',
  })

  const formattedTime = getFormattedDate(date, lang, {
    hour: 'numeric',
    minute: 'numeric',
  })

  const isToday = dayjs(date).isToday()
  const isYesterday = dayjs(date).isYesterday()

  if (isToday) {
    return `${
      /*i18n*/
      i18n._('date.today')
    } ${formattedTime}`
  }

  if (isYesterday) {
    return `${
      /*i18n*/
      i18n._('date.yesterday')
    } ${formattedTime}`
  }

  return `${formattedDate} ${formattedTime}`
}

export const floorPercentageDecimalNumber = (value: string) => {
  const percentageModulo = 1000

  return Math.floor(Number(value) * percentageModulo) / percentageModulo
}

export const getRemainingDays = (date: Date | string) =>
  dayjs(date).diff(dayjs(), 'days')

export const getRemainingTime = (date: Date | string) => {
  const day = 24
  const remainingDays = getRemainingDays(date)
  const remainingHours = dayjs(date).diff(dayjs(), 'hours') % day

  return { days: remainingDays, hours: remainingHours }
}

export const getDueDays = (dateDue: Date | string) =>
  Math.ceil(dayjs(dateDue).diff(dayjs(), 'days', true))
