import {
  appendSearchToRoutes,
  getLocationSearch,
  prependPathToRoutes,
} from '@twisto/utils'

const basepath = '/auth'

const routesDefinition = {
  index: {
    path: '/',
  },
  login: {
    path: '/login/',
  },
  resetPassword: {
    path: '/reset-password/:secretKey',
  },
  setInitialPassword: {
    path: '/set-initial-password/',
  },
  mfaCheck: {
    path: '/mfa-check/',
  },
  securityCodeCheck: {
    path: '/security-code-check/',
  },
  setSecurityCode: {
    path: '/set-security-code/',
  },
} as const

const locationSearch = getLocationSearch()
export const routesMatch = prependPathToRoutes(basepath, routesDefinition)
export const routes = locationSearch
  ? appendSearchToRoutes(locationSearch, routesMatch)
  : routesMatch
