import type { ComponentProps, ReactElement } from 'react'

import { useTheme } from '@emotion/react'

import type { IconComponent } from '@twisto/icons'

import { BaseButton } from '../base-button'
import { ButtonRippleAnimation } from '../button-ripple-animation'

import type { Colors, Sizes } from './icon-button.styles'
import * as styles from './icon-button.styles'
import type { DEFAULT_TOOLTIP } from './tooltip-wrapper'
import { TooltipWrapper } from './tooltip-wrapper'

type Props = {
  color?: Colors
  defaultTooltip?: keyof typeof DEFAULT_TOOLTIP
  icon: IconComponent
  size?: Sizes
  tooltip?: string | ReactElement
  'data-testid'?: string
} & ComponentProps<typeof BaseButton>

export const IconButton = ({
  color = 'primary',
  defaultTooltip,
  icon,
  size = 'medium',
  tooltip,
  ...buttonProps
}: Props) => {
  const theme = useTheme()
  const Icon = icon

  const rippleColorMap: Record<Colors, string> = {
    primary: theme.palette.other.overlayWhite12,
    secondary: theme.palette.additionalColor.purple[100],
    negative: theme.palette.error[100],
    warning: theme.palette.warning[100],
    neutral: theme.palette.neutral[100],
    ghost: theme.palette.neutral[100],
  }

  return (
    <ButtonRippleAnimation color={rippleColorMap[color]}>
      {({ rippleButtonStyles, ref, ripples }) => (
        <BaseButton
          ref={ref}
          css={[
            rippleButtonStyles,
            styles.colors(theme)[color],
            styles.iconButton,
            styles.sizes[size],
          ]}
          data-testid="icon-button"
          {...buttonProps}
        >
          {ripples}
          <TooltipWrapper defaultTooltip={defaultTooltip} tooltip={tooltip}>
            <Icon color="inherit" size={size === 'small' ? '20' : '24'} />
          </TooltipWrapper>
        </BaseButton>
      )}
    </ButtonRippleAnimation>
  )
}
