export const networkStatusCode = {
  noConnection: 0,
  success: 200,
  successCreated: 201,
  successNoContent: 204,
  temporaryRedirect: 307,
  permanentRedirect: 308,
  badRequest: 400,
  unauthorized: 401,
  permissionDenied: 403,
  notFound: 404,
  timeout: 408,
  preconditionFailed: 412,
  internalServerError: 500,
} as const
