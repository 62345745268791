import { prependPathToRoutes } from '@twisto/utils'

export const basepath = '/expenses'

const routesDefinition = {
  overview: {
    path: '/',
  },
  transaction: {
    path: '/transaction/:transactionId/*',
    getPath: ({ transactionId }: { transactionId: string }) =>
      `/transaction/${transactionId}/` as const,
  },
  category: {
    path: '/category/:category/',
    getPath: ({ category }: { category: string }) =>
      `/category/${category}/` as const,
  },
  transactionInCategory: {
    path: '/category/:category/transaction/:transactionId/*',
    getPath: ({
      category,
      transactionId,
    }: {
      category: string
      transactionId: string
    }) => `/category/${category}/transaction/${transactionId}/` as const,
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)
