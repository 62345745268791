import { useRouter } from 'next/router'

import { matchPath } from '@twisto/utils'

export const useMatch = <Args extends Record<string, string>>(
  matchPathname: string
) => {
  const router = useRouter()

  const matchResult = matchPath<Args>(matchPathname, router.asPath)

  if (matchResult === false) {
    return null
  }

  return { path: matchResult.path, ...matchResult.params }
}
