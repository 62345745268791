import { prependPathToRoutes } from '@twisto/utils'

const basepath = '/donations'

const routesDefinition = {
  intro: {
    path: '/project/:projectId/',
    getPath: ({ projectId }: { projectId: string }) =>
      `/project/${projectId}/` as const,
  },
  donateAction: {
    path: '/project/:projectId/donate/',
    getPath: ({ projectId }: { projectId: string }) =>
      `/project/${projectId}/donate/` as const,
  },
  success: {
    path: '/success/',
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)
