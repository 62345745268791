import { prependPathToRoutes } from '@twisto/utils'

export const basepath = '/overview'

const routesDefinition = {
  dashboard: {
    path: '/',
  },
  paymentIntent: {
    path: '/payment-intent/*',
  },
  limitOffer: {
    path: '/limit-offer/:id/',
    oldPath: '/limit-increase/:id/',
    getPath: (id: string) => `/limit-offer/${id}/` as const,
  },
  recentPayments: {
    path: '/recent-payments/',
    wildcardPath: '/recent-payments/*',
  },
  dividablePayments: {
    path: '/dividable-payments/',
    wildcardPath: '/dividable-payments/*',
  },
  pricingSelection: {
    path: '/pricing-selection/',
  },
  openedViewPayment: {
    path: '/payment/:paymentId/:openedView/',
  },
  payment: {
    path: '/payment/:paymentId/*',
    getPath: (paymentId: string) => `/payment/${paymentId}/` as const,
    recentPayments: {
      path: '/recent-payments/payment/:paymentId/*',
      getPath: (paymentId: string) =>
        `/recent-payments/payment/${paymentId}/` as const,
    },
    dividablePayments: {
      path: '/dividable-payments/payment/:paymentId/*',
      getPath: (paymentId: string) =>
        `/dividable-payments/payment/${paymentId}/` as const,
    },
  },
  merchantBenefitDetail: {
    path: '/merchant-benefit/:merchantBenefitId',
    getPath: ({ merchantBenefitId }: { merchantBenefitId: string }) =>
      `/merchant-benefit/${merchantBenefitId}/` as const,
  },
  splitRequest: {
    path: '/split-request/:id/',
    getPath: (id: string) => `/split-request/${id}/` as const,
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)
