import { useEffect, useState } from 'react'

import { Trans } from '@lingui/react'

import { useEmptyLazyQuery } from '@twisto/api-graphql'
import { TextButton } from '@twisto/components/atoms/button'
import { Loader } from '@twisto/components/atoms/loader'
import {
  Modal,
  ModalActions,
  ModalContentText,
  ModalImage,
  ModalTitle,
} from '@twisto/components/atoms/modal'
import { BigGhostPendingIcon } from '@twisto/icons'
import { styleUtils } from '@twisto/styles'

import { usePinLock } from './pin-lock-timer.context'
import * as styles from './timeout-modal.styles'

const modalTitleId = 'pretimeout-alert-dialog-title'

export const TimeoutModal = () => {
  const { timeoutModalDuration, showTimeoutModal } = usePinLock()

  const [secondsLeft, setSecondsLeft] = useState(timeoutModalDuration)
  const [fetchEmptyQuery, { loading }] = useEmptyLazyQuery({
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    let seconds = timeoutModalDuration

    const updateSeconds = () => {
      seconds = seconds - 1
      setSecondsLeft(seconds)
    }

    if (showTimeoutModal) {
      setSecondsLeft(seconds)
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const timerId = window.setInterval(updateSeconds, 1000)

      return () => window.clearInterval(timerId)
    }
  }, [showTimeoutModal, timeoutModalDuration])

  return (
    <Modal
      aria-labelledby={modalTitleId}
      modalOverlayStyles={styles.modalOverlay}
      opened={showTimeoutModal}
      variant="centered"
    >
      {loading && <Loader />}
      <ModalImage>
        <BigGhostPendingIcon color="warning" size="96" />
      </ModalImage>
      <ModalTitle
        align="center"
        css={styleUtils.margin.bottom.lg}
        id={modalTitleId}
      >
        <Trans id="component.timeoutModal.title" />
      </ModalTitle>
      <ModalContentText align="center" color="textSecondary" variant="b2">
        <Trans id="component.timeoutModal.content" values={{ secondsLeft }} />
      </ModalContentText>
      <ModalActions>
        <TextButton
          fullWidth
          disabled={loading}
          onClick={() => fetchEmptyQuery()}
        >
          <Trans id="component.timeoutModal.button" />
        </TextButton>
      </ModalActions>
    </Modal>
  )
}
