import { useEffect } from 'react'

import { usePrevious } from './previous.hook'

export const useChangeCallback = (
  callback: () => void | undefined | Promise<void>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any
) => {
  const previousResponse = usePrevious(response)

  useEffect(() => {
    if (response !== previousResponse) {
      callback()
    }
  }, [callback, previousResponse, response])
}
