import {
  appendSearchToRoutes,
  getLocationSearch,
  hasString,
  prependPathToRoutes,
} from '@twisto/utils'

const basepath = '/signup'

const paths = {
  email: '/email/',
  notificationEmailSent: '/notification-email-sent/',
} as const

const routesDefinition = {
  index: '/',
  email: {
    path: paths.email,
  },
  notificationEmailSent: {
    previous: paths.email,
    path: paths.notificationEmailSent,
  },
} as const

const locationSearch = getLocationSearch()
// if there is `order` search param we want to keep across route transitions
const parsedSearch = new URLSearchParams(locationSearch)
const orderId = parsedSearch.get('order')
export const orderQueryString = hasString(orderId)
  ? `?order=${orderId}`
  : undefined

// for purposes of router path matching
export const routesMatch = prependPathToRoutes(basepath, routesDefinition)

// for purposes of links
export const routes =
  orderQueryString !== undefined
    ? appendSearchToRoutes(orderQueryString, routesMatch)
    : routesMatch
