import { prependPathToRoutes } from '@twisto/utils'

const basepath = '/shopping'

const filteredBenefitsPath = '/cashbacks'
const filteredMerchantsPath = '/free-installments'

const routesDefinition = {
  overview: {
    path: '/',
  },
  inStore: {
    path: '/in-store/',
  },
  benefitDetail: {
    path: '/detail/:benefitId/',
    getPath: ({ benefitId }: { benefitId: string }) =>
      `/detail/${benefitId}/` as const,
  },
  merchantBenefitDetail: {
    path: '/merchant-benefit/:merchantBenefitId',
    getPath: ({ merchantBenefitId }: { merchantBenefitId: string }) =>
      `/merchant-benefit/${merchantBenefitId}/` as const,
  },
  filteredMerchants: {
    path: `${filteredMerchantsPath}/`,
  },
  filteredBenefits: {
    path: `${filteredBenefitsPath}/`,
  },
  filteredBenefitsDetail: {
    path: `${filteredBenefitsPath}/detail/:benefitId/`,
    getPath: ({ benefitId }: { benefitId: string }) =>
      `${filteredBenefitsPath}/detail/${benefitId}/` as const,
  },
  filteredMerchantsDetail: {
    path: `${filteredMerchantsPath}/merchant/:merchantId/`,
    getPath: ({ merchantId }: { merchantId: string }) =>
      `${filteredMerchantsPath}/merchant/${merchantId}/` as const,
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)
