import type { ReactNode } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

type PinLockApi = {
  reset: () => void
  showTimeoutModal: boolean
  timeoutModalDuration: number
}

const PinLockTimerContext = createContext<null | PinLockApi>(null)

type ProviderProps = {
  children: ReactNode
  pinLockEnabled: boolean
}

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const timeout = 10 * 60 + 5 // 10:05 minutes
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const triggerTimeoutDialog = timeout - 1 * 60 // timeout - 1 minute
const secondInMiliseconds = 1000

export const PinLockTimerProvider = ({
  children,
  pinLockEnabled,
}: ProviderProps) => {
  // state for openning timeout modal
  const [showTimeoutModal, setShowTimeoutModal] = useState(false)

  const timeoutId = useRef<NodeJS.Timeout>()
  const openModalTimeoutId = useRef<NodeJS.Timeout>()

  const clear = useCallback(() => {
    setShowTimeoutModal(false)

    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    if (openModalTimeoutId.current) {
      clearTimeout(openModalTimeoutId.current)
    }
  }, [])

  const set = useCallback(() => {
    clear()

    timeoutId.current = setTimeout(() => {
      window.location.reload()
    }, timeout * secondInMiliseconds)

    // this sets state for opening modal a minute before timeout expires
    openModalTimeoutId.current = setTimeout(() => {
      setShowTimeoutModal(true)
    }, triggerTimeoutDialog * secondInMiliseconds)
  }, [clear])

  // reset should set new timeout only if it was previously set
  const reset = useCallback(() => {
    if (timeoutId.current && openModalTimeoutId.current) {
      set()
    }
  }, [set])

  useEffect(() => {
    if (pinLockEnabled) {
      set()
    } else {
      clear()
    }
  }, [clear, pinLockEnabled, set])

  const api: PinLockApi = {
    showTimeoutModal,
    timeoutModalDuration: timeout - triggerTimeoutDialog,
    reset,
  }

  return (
    <PinLockTimerContext.Provider value={api}>
      {children}
    </PinLockTimerContext.Provider>
  )
}

export const usePinLock = () => {
  const context = useContext(PinLockTimerContext)

  if (!context) {
    throw new Error('usePinLock must be used within a PinLockProvider')
  }

  return context
}
