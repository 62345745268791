import type { ReactElement, ReactNode } from 'react'
import { useEffect, useRef, useState } from 'react'

import { animated, useTransition } from '@react-spring/web'
import { createPortal } from 'react-dom'

import { Typography } from '../typography'

import { useTooltipPosition } from './tooltip-position.hook'
import * as styles from './tooltip.styles'

/**
 * @typedef {Object} Props
 */
type Props = {
  children: ReactNode
  className?: string
  id: string
  text: string | ReactElement
}

/**
 * A wrapper that provides a tooltip bubble that displays a description of the element that triggers it.
 * @param {Props} props
 * @param {ReactNode} props.children - the element that triggers the tooltip
 * @param {string?} props.className - the class name to apply to the tooltip wrapper
 * @param {string} props.id - the unique identifier for `aria-describedby` to associate the tooltip with the element that triggers it
 * @param {string|ReactElement} props.text - the text to display in the tooltip
 */
export const Tooltip = ({ children, className, id, text }: Props) => {
  const triggerElementRef = useRef<HTMLDivElement>(null)
  const tooltipElementRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState)
  }

  const setVisibilityTo = (value: boolean) => () => {
    setIsVisible(value)
  }

  // Hide tooltip when user presses the Escape key
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isVisible) {
        setIsVisible(false)
      }
    }

    document.addEventListener('keydown', handleEscape)

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [isVisible])

  const tooltipPosition = useTooltipPosition({
    triggerElementRef,
    tooltipElementRef,
    isVisible,
  })

  const transitionAnimation = useTransition(isVisible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <div
      ref={triggerElementRef}
      className={className}
      css={styles.wrapper}
      role="tooltip"
      tabIndex={0}
      onBlur={setVisibilityTo(false)}
      onClick={toggleVisibility}
      onFocus={setVisibilityTo(true)}
      onMouseEnter={setVisibilityTo(true)}
      onMouseLeave={setVisibilityTo(false)}
    >
      {createPortal(
        <section css={styles.tooltipPortal}>
          {transitionAnimation((style, item) =>
            item ? (
              <animated.div
                ref={tooltipElementRef}
                css={styles.tooltip}
                id={id}
                role="tooltip"
                style={{
                  ...style,
                  left: tooltipPosition?.x ?? '-1000',
                  top: tooltipPosition?.y ?? '-1000',
                }}
              >
                <div
                  css={[
                    styles.content,
                    tooltipPosition?.arrowOrientation === 'upStart' &&
                      styles.topArrowStartMask,
                    tooltipPosition?.arrowOrientation === 'upCenter' &&
                      styles.topArrowCenterMask,
                    tooltipPosition?.arrowOrientation === 'upEnd' &&
                      styles.topArrowEndMask,
                    tooltipPosition?.arrowOrientation === 'downStart' &&
                      styles.bottomArrowStartMask,
                    tooltipPosition?.arrowOrientation === 'downCenter' &&
                      styles.bottomArrowCenterMask,
                    tooltipPosition?.arrowOrientation === 'downEnd' &&
                      styles.bottomArrowEndMask,
                    tooltipPosition?.arrowOrientation === 'sideStart' &&
                      styles.sideArrowStartMask,
                    tooltipPosition?.arrowOrientation === 'sideEnd' &&
                      styles.sideArrowEndMask,
                  ]}
                >
                  <Typography variant="b4">{text}</Typography>
                </div>
              </animated.div>
            ) : null
          )}
        </section>,

        document.body
      )}
      <div aria-describedby={id} css={styles.tooltipTriggerElement}>
        {children}
      </div>
    </div>
  )
}
