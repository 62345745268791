import { useAtom } from 'jotai'
import { equals, isNil } from 'ramda'

import { useSectionsAvailabilityQuery } from '@twisto/api-graphql'
import type { Sections } from '@twisto/api-graphql'
import { atomWithLocalStorage } from '@twisto/utils'

export const remoteSectionsAvailabilityAtom = atomWithLocalStorage<
  Sections | undefined
>('remoteSectionsAvailability', undefined)

export const RemoteSectionsAvailabilityLoader = () => {
  const [store, setSectionsAvailability] = useAtom(
    remoteSectionsAvailabilityAtom
  )

  useSectionsAvailabilityQuery({
    onCompleted: (data) => {
      if (isNil(store) || !equals(store, data.sections)) {
        setSectionsAvailability(data.sections)
      }
    },
  })

  return null
}
