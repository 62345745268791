import type { ComponentProps } from 'react'

import { i18n } from '@lingui/core'

import { IconButton } from '@twisto/components/atoms/button'
import { useIsMobile } from '@twisto/hooks'
import type { IconComponent } from '@twisto/icons'
import { BackIcon } from '@twisto/icons'
import type { RequireOnlyOne } from '@twisto/utils'

type Props = RequireOnlyOne<
  {
    to: string
    href: string
    onClick: () => void
    className?: string
    icon?: IconComponent | null
  },
  'to' | 'href' | 'onClick'
> &
  Omit<
    ComponentProps<typeof IconButton>,
    'onClick' | 'to' | 'href' | 'children' | 'icon'
  >

export const BackButton = ({ icon, ...props }: Props) => {
  const isMobile = useIsMobile()
  const Icon = icon ?? BackIcon

  return (
    <IconButton
      aria-label={/*i18n*/ i18n._('modal.slide.goBack')}
      className={props.className}
      color="neutral"
      data-testid="back-button"
      icon={Icon}
      size={isMobile ? 'small' : 'medium'}
      {...props}
    />
  )
}
