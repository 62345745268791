import { prependPathToRoutes } from '@twisto/utils'

const basePath = '/settings'

const routesDefinition = {
  index: {
    path: '/',
  },
  general: {
    path: '/general',
  },
  paymentMethods: {
    path: '/payment-methods/',
  },
  emails: {
    path: '/emails/',
  },
  language: {
    path: '/language/',
  },
  bankAccounts: {
    path: '/bank-accounts/',
  },
  devices: {
    path: '/devices/',
  },
  appearance: {
    path: '/appearance/',
  },
  directDebit: {
    path: '/direct-debit/*',
    getPath: () => '/direct-debit/' as const,
    paths: {
      index: '/direct-debit/',
      setDirectDebit: '/direct-debit/set-direct-debit/',
      success: '/direct-debit/success/',
      internetBankingSetup: '/direct-debit/internet-banking-setup/',
      internetBanking: '/direct-debit/internet-banking/',
    },
  },
} as const

export const routes = prependPathToRoutes(basePath, routesDefinition)
