import { prependPathToRoutes } from '@twisto/utils'

export const basepath = '/snap'

const routesDefinition = {
  overview: {
    path: '/',
  },
  invoiceSnapDetail: {
    path: `/invoice-detail/:snapId/`,
    getPath: (snapId: string) => `/invoice-detail/${snapId}/` as const,
  },
  emailSnapDetail: {
    path: `/email-detail/:snapId/`,
    getPath: (snapId: string) => `/email-detail/${snapId}/` as const,
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)
