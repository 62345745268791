import { prependPathToRoutes } from '@twisto/utils'

export const basepath = '/cash'

const routesDefinition = {
  overview: {
    path: '/',
  },
  overviewWithSessionId: {
    path: '/:sessionId/',
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)
