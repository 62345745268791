import { prependPathToRoutes } from '@twisto/utils'

const basepath = '/installments'

const installmentScheduleDetail = {
  path: `/detail/:installmentScheduleId/*`,
  getPath: (installmentScheduleId: string) =>
    `/detail/${installmentScheduleId}/` as const,
} as const

const createScheduleFlowRoutes = {
  createSchedule: {
    path: `/create/:referenceId/offer/`,
    getPath: (invoiceId: string) => `/create/${invoiceId}/offer/` as const,
  },
  minimumDueAmount: {
    path: `/create/:referenceId/minimum-due-amount/`,
    getPath: (installmentScheduleId: string) =>
      `/create/${installmentScheduleId}/minimum-due-amount/` as const,
  },
  verifyScheduleContract: {
    path: `/create/:referenceId/contract/`,
    getPath: (installmentScheduleId: string) =>
      `/create/${installmentScheduleId}/contract/` as const,
  },
  signScheduleContract: {
    path: `/create/:referenceId/sign/`,
    getPath: (installmentScheduleId: string) =>
      `/create/${installmentScheduleId}/sign/` as const,
  },
  createScheduleSuccess: {
    path: `/create/:referenceId/success/`,
    getPath: (installmentScheduleId: string) =>
      `/create/${installmentScheduleId}/success/` as const,
  },
} as const

const routesDefinition = {
  overview: {
    path: '/',
  },
  paymentDetail: {
    path: '/payment/:transactionId/*',
    getPath: (id: string) => `/payment/${id}/` as const,
  },
  installmentScheduleDetail,
  transaction: {
    path: `/detail/:installmentScheduleId/transaction/:transactionId/`,
    getPath: ({
      installmentScheduleId,
      transactionId,
    }: {
      installmentScheduleId: string
      transactionId: string
    }) =>
      `/detail/${installmentScheduleId}/transaction/${transactionId}/` as const,
  },
  dividablePayments: {
    pathMatch: '/dividable-payments/*',
    path: '/dividable-payments/',
    paymentDetail: {
      path: '/dividable-payments/:transactionId/*',
      getPath: (id: string) => `/dividable-payments/${id}/` as const,
    },
  },
  dividedPayments: {
    pathMatch: '/divided-payments/*',
    path: '/divided-payments/',
    paymentDetail: {
      path: '/divided-payments/:transactionId/',
      getPath: (id: string) => `/divided-payments/${id}/` as const,
    },
  },
  createScheduleFlow: {
    path: `/create/:referenceId/*`,
    routes: createScheduleFlowRoutes,
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)
