import { prependPathToRoutes } from '@twisto/utils'

const basePath = '/documents'

const routesDefinition = {
  index: {
    path: '/',
  },
} as const

export const routes = prependPathToRoutes(basePath, routesDefinition)
