import { useLingui } from '@lingui/react'

import type { Section } from './'

export const usePageTitleBySection = (section: Section) => {
  const { i18n } = useLingui()

  const pageTitles: Record<Section, string> = {
    auth:
      /*i18n*/
      i18n._('auth.section.name'),
    signup:
      /*i18n*/
      i18n._('signup.section.name'),
    verification:
      /*i18n*/
      i18n._('verification.section.name'),
    overview:
      /*i18n*/
      i18n._('overview.section.name'),
    expenses:
      /*i18n*/
      i18n._('expenses.section.name'),
    shopping:
      /*i18n*/
      i18n._('shopping.section.name'),
    card:
      /*i18n*/
      i18n._('card.section.name'),
    billing:
      /*i18n*/
      i18n._('billing.section.name'),
    installments:
      /*i18n*/
      i18n._('installments.section.name'),
    insurance:
      /*i18n*/
      i18n._('travelInsurance.section.name'),
    cash:
      /*i18n*/
      i18n._('cash.section.name'),
    settings:
      /*i18n*/
      i18n._('settings.section.name'),
    documents:
      /*i18n*/
      i18n._('documents.section.name'),
    referral:
      /*i18n*/
      i18n._('referral.section.name'),
    credits:
      /*i18n*/
      i18n._('credits.section.name'),
    snap:
      /*i18n*/
      i18n._('snap.section.name'),
    donations:
      /*i18n*/
      i18n._('donations.section.name'),
    planChange:
      /*i18n*/
      i18n._('planChange.section.name'),
    limits:
      /*i18n*/
      i18n._('limits.section.name'),
    notFound:
      /*i18n*/
      i18n._('page.title.notFound'),
  }

  return pageTitles[section]
}
