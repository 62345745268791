import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { borderRadius, duration, easing, size } from '@twisto/styles'

export type Colors = keyof ReturnType<typeof colors>
export type Sizes = keyof typeof sizes

export const iconButton = (theme: Theme) => css`
  border: none;
  padding: 0;
  margin: 0;
  min-height: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  aspect-ratio: 1 / 1;
  border-radius: ${borderRadius.circle};
  transition: background ${duration.short}ms ${easing.easeIn};
  flex-shrink: 0;

  &[disabled] {
    color: ${theme.palette.neutral[600]};
    background: ${theme.palette.neutral[100]};
    pointer-events: none;
  }
`

const colorPseudoClasses = ({
  hoverBackgroundColor,
  focusOutlineColor,
}: {
  hoverBackgroundColor: string
  focusOutlineColor: string
}) => css`
  &:hover,
  &:focus {
    background: ${hoverBackgroundColor};
    transition: background ${duration.short}ms ${easing.easeOut};
  }

  &:focus-visible {
    outline: 2px solid ${focusOutlineColor};
    outline-offset: 2px;
    transition: background ${duration.short}ms ${easing.easeOut};
  }
`

export const colors = (theme: Theme) => ({
  primary: css`
    color: ${theme.palette.action['000']};
    background: ${theme.palette.action[500]};

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.action[400],
      focusOutlineColor: theme.palette.brand[200],
    })}
  `,
  secondary: css`
    color: ${theme.palette.brand[500]};
    background: ${theme.palette.brand[100]};

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.brand[200],
      focusOutlineColor: theme.palette.brand[200],
    })}
  `,
  negative: css`
    color: ${theme.palette.error[500]};
    background: ${theme.palette.error[100]};

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.error[200],
      focusOutlineColor: theme.palette.error[200],
    })}
  `,
  warning: css`
    color: ${theme.palette.warning[500]};
    background: ${theme.palette.warning[100]};

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.warning[200],
      focusOutlineColor: theme.palette.warning[200],
    })}
  `,
  neutral: css`
    color: ${theme.palette.neutral[700]};
    background: ${theme.palette.neutral[200]};

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.neutral[300],
      focusOutlineColor: theme.palette.neutral[300],
    })}
  `,
  ghost: css`
    color: ${theme.palette.neutral[700]};
    background: transparent;

    ${colorPseudoClasses({
      hoverBackgroundColor: theme.palette.neutral[100],
      focusOutlineColor: theme.palette.neutral[200],
    })}
  `,
})

export const sizes = {
  small: css`
    // size 6 is not defined in our design system
    padding: calc(${size[4]} * 1.5);
  `,
  medium: css`
    padding: ${size[8]};
  `,
}
