import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { size, zIndex } from '@twisto/styles'

export const wrapper = css`
  position: relative;
`

export const tooltipPortal = css`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: ${zIndex.tooltip};
`

export const tooltip = css`
  position: absolute;
  max-width: calc(400px + 2 * ${size[12]});
  min-width: calc(80px + 2 * ${size[12]});
`

export const tooltipTriggerElement = css`
  cursor: pointer;
`

export const content = (theme: Theme) => css`
  grid-area: content;
  padding: ${size[20]};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${theme.palette.action['000']};
  background-color: ${theme.palette.other.overlayTextBg};
  mask-repeat: no-repeat;
`

const TOOLTIP_BOX_MASK = `
    url('data:image/svg+xml;utf8,<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4" /></svg>'),
    url('data:image/svg+xml;utf8,<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4" /></svg>'),
    url('data:image/svg+xml;utf8,<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4" /></svg>'),
    url('data:image/svg+xml;utf8,<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4" /></svg>'),
    linear-gradient(#000, #000), linear-gradient(#000, #000)
`

const TOOLTIP_ARROW_MASK = {
  TOP_START: `url('data:image/svg+xml;utf8,<svg width="16" height="8" viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg"><path d="M0 8H16L9.41421 1.41421C8.63317 0.633165 7.36684 0.633165 6.58579 1.41421L0 8Z"/></svg>')`,
  TOP_CENTER: `url('data:image/svg+xml;utf8,<svg width="16" height="8" viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg"><path d="M0 8H16L9.41421 1.41421C8.63317 0.633165 7.36684 0.633165 6.58579 1.41421L0 8Z"/></svg>')`,
  TOP_END: `url('data:image/svg+xml;utf8,<svg width="16" height="8" viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg"><path d="M0 8H16L9.41421 1.41421C8.63317 0.633165 7.36684 0.633165 6.58579 1.41421L0 8Z"/></svg>')`,
  SIDE_START: `url('data:image/svg+xml;utf8,<svg width="8" height="16" viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg"><path d="M8 16L8 9.5399e-08L1.41421 6.58579C0.633165 7.36683 0.633165 8.63316 1.41421 9.41421L8 16Z"/></svg>')`,
  SIDE_END: `url('data:image/svg+xml;utf8,<svg width="8" height="16" viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg"><path d="M0 -3.49691e-07L-6.99382e-07 16L6.58579 9.41421C7.36683 8.63317 7.36683 7.36684 6.58579 6.58579L0 -3.49691e-07Z"/></svg>')`,
  BOTTOM_START: `url('data:image/svg+xml;utf8,<svg width="16" height="8" viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg"><path d="M16 0L6.99382e-07 -1.39876e-06L6.58579 6.58579C7.36683 7.36683 8.63316 7.36683 9.41421 6.58579L16 0Z"/></svg>')`,
  BOTTOM_CENTER: `url('data:image/svg+xml;utf8,<svg width="16" height="8" viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg"><path d="M16 0L6.99382e-07 -1.39876e-06L6.58579 6.58579C7.36683 7.36683 8.63316 7.36683 9.41421 6.58579L16 0Z"/></svg>')`,
  BOTTOM_END: `url('data:image/svg+xml;utf8,<svg width="16" height="8" viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg"><path d="M16 0L6.99382e-07 -1.39876e-06L6.58579 6.58579C7.36683 7.36683 8.63316 7.36683 9.41421 6.58579L16 0Z"/></svg>')`,
} as const

const TOOLTIP_BOX_MASK_POSITION = `
  8px 8px,
  calc(100% - 8px) 8px,
  calc(100% - 8px) calc(100% - 8px),
  8px calc(100% - 8px),
  8px 12px,
  12px 8px
`

const TOOLTIP_ARROW_MASK_POSITION = {
  TOP_START: `20px 1px`,
  TOP_CENTER: `50% 1px`,
  TOP_END: `calc(100% - 20px) 1px`,
  SIDE_START: `1px 50%`,
  SIDE_END: `calc(100% - 1px) 50%`,
  BOTTOM_START: `20px calc(100% - 1px)`,
  BOTTOM_CENTER: `50% calc(100% - 1px)`,
  BOTTOM_END: `calc(100% - 20px) calc(100% - 1px)`,
} as const

const TOOLTIP_BOX_MASK_SIZE = `
  8px 8px,
  8px 8px,
  8px 8px,
  8px 8px,
  calc(100% - 16px) calc(100% - 24px),
  calc(100% - 24px) calc(100% - 16px)
`

const TOOLTIP_ARROW_MASK_SIZE = {
  TOP_BOTTOM: `16px 8px`,
  SIDE: `8px 16px`,
} as const

export const topArrowStartMask = css`
  mask-image: ${TOOLTIP_BOX_MASK}, ${TOOLTIP_ARROW_MASK.TOP_START};
  mask-position: ${TOOLTIP_BOX_MASK_POSITION},
    ${TOOLTIP_ARROW_MASK_POSITION.TOP_START};
  mask-size: ${TOOLTIP_BOX_MASK_SIZE}, ${TOOLTIP_ARROW_MASK_SIZE.TOP_BOTTOM};
`

export const topArrowCenterMask = css`
  mask-image: ${TOOLTIP_BOX_MASK}, ${TOOLTIP_ARROW_MASK.TOP_CENTER};
  mask-position: ${TOOLTIP_BOX_MASK_POSITION},
    ${TOOLTIP_ARROW_MASK_POSITION.TOP_CENTER};
  mask-size: ${TOOLTIP_BOX_MASK_SIZE}, ${TOOLTIP_ARROW_MASK_SIZE.TOP_BOTTOM};
`

export const topArrowEndMask = css`
  mask-image: ${TOOLTIP_BOX_MASK}, ${TOOLTIP_ARROW_MASK.TOP_END};
  mask-position: ${TOOLTIP_BOX_MASK_POSITION},
    ${TOOLTIP_ARROW_MASK_POSITION.TOP_END};
  mask-size: ${TOOLTIP_BOX_MASK_SIZE}, ${TOOLTIP_ARROW_MASK_SIZE.TOP_BOTTOM};
`

export const sideArrowStartMask = css`
  mask-image: ${TOOLTIP_BOX_MASK}, ${TOOLTIP_ARROW_MASK.SIDE_START};
  mask-position: ${TOOLTIP_BOX_MASK_POSITION},
    ${TOOLTIP_ARROW_MASK_POSITION.SIDE_START};
  mask-size: ${TOOLTIP_BOX_MASK_SIZE}, ${TOOLTIP_ARROW_MASK_SIZE.SIDE};
`

export const sideArrowEndMask = css`
  mask-image: ${TOOLTIP_BOX_MASK}, ${TOOLTIP_ARROW_MASK.SIDE_END};
  mask-position: ${TOOLTIP_BOX_MASK_POSITION},
    ${TOOLTIP_ARROW_MASK_POSITION.SIDE_END};
  mask-size: ${TOOLTIP_BOX_MASK_SIZE}, ${TOOLTIP_ARROW_MASK_SIZE.SIDE};
`

export const bottomArrowStartMask = css`
  mask-image: ${TOOLTIP_BOX_MASK}, ${TOOLTIP_ARROW_MASK.BOTTOM_START};
  mask-position: ${TOOLTIP_BOX_MASK_POSITION},
    ${TOOLTIP_ARROW_MASK_POSITION.BOTTOM_START};
  mask-size: ${TOOLTIP_BOX_MASK_SIZE}, ${TOOLTIP_ARROW_MASK_SIZE.TOP_BOTTOM};
`

export const bottomArrowCenterMask = css`
  mask-image: ${TOOLTIP_BOX_MASK}, ${TOOLTIP_ARROW_MASK.BOTTOM_CENTER};
  mask-position: ${TOOLTIP_BOX_MASK_POSITION},
    ${TOOLTIP_ARROW_MASK_POSITION.BOTTOM_CENTER};
  mask-size: ${TOOLTIP_BOX_MASK_SIZE}, ${TOOLTIP_ARROW_MASK_SIZE.TOP_BOTTOM};
`

export const bottomArrowEndMask = css`
  mask-image: ${TOOLTIP_BOX_MASK}, ${TOOLTIP_ARROW_MASK.BOTTOM_END};
  mask-position: ${TOOLTIP_BOX_MASK_POSITION},
    ${TOOLTIP_ARROW_MASK_POSITION.BOTTOM_END};
  mask-size: ${TOOLTIP_BOX_MASK_SIZE}, ${TOOLTIP_ARROW_MASK_SIZE.TOP_BOTTOM};
`
