import type { ReactElement } from 'react'

import { Trans } from '@lingui/react'
import { isNil } from 'ramda'

import { Tooltip } from '@twisto/components/atoms/tooltip'

type Props = {
  tooltip?: string | ReactElement
  defaultTooltip?: keyof typeof DEFAULT_TOOLTIP
  children: ReactElement
}

export const DEFAULT_TOOLTIP = {
  copy: <Trans id="iconButton.action.copy" />,
  delete: <Trans id="iconButton.action.delete" />,
  displayMore: <Trans id="iconButton.action.displayMore" />,
  edit: <Trans id="iconButton.action.edit" />,
}

export const TooltipWrapper = ({
  children,
  defaultTooltip,
  tooltip,
}: Props) => {
  if (isNil(tooltip) && isNil(defaultTooltip)) {
    return <>{children}</>
  }

  const label =
    tooltip ?? DEFAULT_TOOLTIP[defaultTooltip as keyof typeof DEFAULT_TOOLTIP]

  return (
    <Tooltip id="iconButton.action.tooltip" text={label}>
      {children}
    </Tooltip>
  )
}
