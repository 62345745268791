import { InMemoryCache } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

import type { DeviceType, TypedTypePolicies } from '@twisto/api-graphql'
import { introspectionResult } from '@twisto/api-graphql'

const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      bills: relayStylePagination(['billFilter']),
      invoices: relayStylePagination(['invoiceFilter', 'orderBy']),
      cardTransactions: relayStylePagination(['cardTransactionFilter']),
      notifications: relayStylePagination(['filter']),
      agreements: relayStylePagination(['agreementFilter']),
      creditTransactions: relayStylePagination(['filter', 'orderBy']),
      invoiceRequests: relayStylePagination(['filter', 'orderBy']),
      devices: {
        merge(_, incoming: DeviceType[]) {
          return [...incoming]
        },
      },
    },
  },
  Flow: { keyFields: [] },
  Step: { keyFields: [] },
  InstallmentSchedules: {
    fields: {
      active: relayStylePagination(),
      paid: relayStylePagination(),
    },
  },
  Account: {
    keyFields: [],
  },
  DueBillsInfo: {
    keyFields: [],
  },
  IdCard: {
    keyFields: [],
  },
  Referral: {
    keyFields: [],
  },
  Selfie: {
    keyFields: [],
  },
  UpgradeRequest: {
    keyFields: [],
  },
  UpgradeRequestExtraInfo: {
    keyFields: [],
  },
  RequestCashSuccess: {
    keyFields: [],
  },
  TrIdentityVerification: {
    keyFields: [],
    merge: true,
  },
  AuthInfo: {
    keyFields: [],
    merge: true,
  },
  LanguageSettings: {
    keyFields: [],
  },
  Invoice: {
    merge: true,
  },
  Bill: {
    merge: true,
  },
  SelectField: {
    keyFields: false,
    merge: true,
  },
  CustomerLimits: {
    keyFields: false,
    merge: true,
  },
  CustomerEmails: {
    keyFields: [],
  },
  SecurityCode: {
    keyFields: [],
    merge: true,
  },
}

export const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies,
})
