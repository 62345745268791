import type { ReactNode } from 'react'
import { useCallback, useContext, useMemo } from 'react'

import { ApolloProvider } from '@apollo/client'
import type { ErrorResponse } from '@apollo/client/link/error'
import { useLingui } from '@lingui/react'

import { getHeaders } from '@twisto/api-common'
import type { I18nLanguage } from '@twisto/base'
import {
  useErrorBoundaryContext,
  useErrorModal,
} from '@twisto/components/molecules/error-boundary'
import { BaseAppContext } from '@twisto/components/organisms/base-app'
import { isDev } from '@twisto/environment'
import { createRedirectUrl } from '@twisto/utils'

import { useCustomerConfig } from 'customer/components/customer-config'
import { TimeoutModal, usePinLock } from 'customer/components/timeout-modal'
import { appRoutes } from 'customer/routes'

import { setupApi } from './config'

type Props = {
  children: ReactNode
}
export const CustomerClientProvider = ({ children }: Props) => {
  const { openConfigEditor } = useContext(BaseAppContext)
  const {
    i18n: { locale },
  } = useLingui()
  const { region, FEDERATED_GRAPHQL_ENDPOINT } = useCustomerConfig()
  const { setError } = useErrorBoundaryContext()
  const { reset: resetPinLockTimer } = usePinLock()
  const { showErrorWithContinue, showDefaultError: handleGraphqlError } =
    useErrorModal()

  const loginPathname = appRoutes.auth.index.path

  const handleAccessDenied = useCallback(
    (error: ErrorResponse) => {
      const context = error.operation.getContext()
      const overrideAccessDeniedHandler =
        (context.overrideAccessDeniedHandler ?? false) as boolean

      if (overrideAccessDeniedHandler) {
        return
      }

      const signupPathMatch = new URL(
        appRoutes.signup.index,
        window.location.origin
      ).pathname

      const authPathMatch = new URL(loginPathname, window.location.origin)
        .pathname

      if (
        window.location.pathname.includes(signupPathMatch) ||
        window.location.pathname.includes(authPathMatch)
      ) {
        return
      }

      if (isDev) {
        // delete token here
        openConfigEditor && openConfigEditor()

        return
      }

      window.location.href = createRedirectUrl(
        loginPathname,
        window.location.pathname
      )
    },
    [loginPathname, openConfigEditor]
  )

  const apiClient = useMemo(
    () =>
      setupApi({
        config: {
          uri: FEDERATED_GRAPHQL_ENDPOINT,
          // @see https://developer.mozilla.org/en-US/docs/Web/API/Request/credentials#Value
          credentials: 'include',
        },
        getHeaders: () =>
          getHeaders({ language: locale as I18nLanguage, region }),
        setError,
        handleAccessDenied,
        handleGraphqlError,
        showErrorWithContinue,
        resetPinLockTimer,
      }),
    [
      FEDERATED_GRAPHQL_ENDPOINT,
      handleAccessDenied,
      handleGraphqlError,
      locale,
      region,
      resetPinLockTimer,
      setError,
      showErrorWithContinue,
    ]
  )

  return (
    <ApolloProvider client={apiClient}>
      <TimeoutModal />
      {children}
    </ApolloProvider>
  )
}
