import { isNil } from 'ramda'

import type { CardType } from '@twisto/api-graphql'
import { prependPathToRoutes } from '@twisto/utils'

const basepath = '/card'

const routesDefinition = {
  overview: {
    path: '/',
  },
  finishRegistration: {
    path: '/finish-registration/',
  },
  intro: {
    path: '/intro/',
  },
  cardDetail: {
    path: '/detail/:cardId/*',
    getPath: ({ cardId }: { cardId: string }) => `/detail/${cardId}/` as const,
  },
  cardTransactions: {
    path: '/detail/:cardId/transactions/',
    getPath: ({ cardId }: { cardId: string }) =>
      `/detail/${cardId}/transactions/` as const,
  },
  cardTransaction: {
    path: '/detail/:cardId/transactions/:transactionId/*',
    getPath: ({
      cardId,
      transactionId,
    }: {
      cardId: string
      transactionId: string
    }) => `/detail/${cardId}/transactions/${transactionId}/` as const,
  },
  allCardTransactions: {
    path: '/transactions/',
  },
  allCardTransaction: {
    path: '/transactions/:transactionId/*',
    getPath: ({ transactionId }: { transactionId: string }) =>
      `/transactions/${transactionId}/` as const,
  },
  physicalCard: {
    path: '/physical/*',
    getPath: () => '/physical/' as const,
  },
  physicalCardOrder: {
    path: '/physical/order/*',
    steps: {
      root: {
        path: '/physical/order/step/',
      },
      plan: {
        path: '/physical/order/step/plan/',
      },
      nickname: {
        path: '/physical/order/step/nickname/',
      },
      design: {
        path: '/physical/order/step/design/',
      },
      delivery: {
        path: '/physical/order/step/delivery/',
      },
    },
    success: {
      path: '/physical/order/success/',
    },
  },
  physicalCardActivate: {
    path: '/physical/activate/',
  },
  physicalCardReactivate: {
    path: '/detail/:cardId/reactivate/*',
    changePlan: {
      path: '/detail/:cardId/reactivate/',
      getPath: ({ cardId }: { cardId: string }) =>
        `/detail/${cardId}/reactivate/` as const,
    },
    success: {
      path: '/detail/:cardId/reactivate/success/',
      getPath: ({ cardId }: { cardId: string }) =>
        `/detail/${cardId}/reactivate/success/` as const,
    },
  },
  virtualCard: {
    path: '/virtual/',
  },
  singleUseVirtualCard: {
    path: '/single-use-virtual/*',
    getPath: () => '/single-use-virtual/' as const,
  },
  success: {
    activateCard: {
      path: '/activate-card-success/',
    },
    changeCardPin: {
      path: '/change-card-pin-success/',
    },
    createVirtualCard: {
      path: '/create-virtual-card-success/',
    },
    changeVirtualCardPin: {
      path: '/change-virtual-card-pin-success/',
    },
  },
  pin: {
    path: '/detail/:cardId/pin/',
    getPath: ({ cardId }: { cardId: string }) =>
      `/detail/${cardId}/pin/` as const,
  },
  cardInfo: {
    path: '/detail/:cardId/info/',
    getPath: ({ cardId }: { cardId: string }) =>
      `/detail/${cardId}/info/` as const,
  },
  clickToPay: {
    path: '/click-to-pay/:cardId/*',
    getPath: ({ cardId }: { cardId: string }) =>
      `/click-to-pay/${cardId}/` as const,
    intro: {
      path: '/click-to-pay/:cardId/',
    },
    reviewInformation: {
      path: '/click-to-pay/:cardId/review-information/',
      getPath: ({ cardId }: { cardId: string }) =>
        `/click-to-pay/${cardId}/review-information/` as const,
    },
    success: {
      path: '/click-to-pay/:cardId/success/',
      getPath: ({ cardId }: { cardId: string }) =>
        `/click-to-pay/${cardId}/success/` as const,
    },
    contract: {
      path: '/click-to-pay/:cardId/contract/',
      getPath: ({ cardId }: { cardId: string }) =>
        `/click-to-pay/${cardId}/contract/` as const,
    },
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)

export const getCardPath = (type: CardType | null, cardId?: string | null) => {
  if (!isNil(cardId)) {
    return routes.cardDetail.getPath({
      cardId,
    })
  }

  switch (type) {
    case 'VIRTUAL':
      return `${routes.virtualCard.path}`
    case 'SINGLE_USE_VIRTUAL':
      return `${routes.singleUseVirtualCard.getPath()}`
    case 'PHYSICAL_CARD':
    default:
      return `${routes.physicalCard.getPath()}`
  }
}
