import { prependPathToRoutes } from '@twisto/utils'

import { routes as authRoutes } from './sections/auth/routes'
import { routes as billingRoutes } from './sections/billing/routes'
import { routes as cardRoutes } from './sections/card/routes'
import { routes as cashRoutes } from './sections/cash/routes'
import { routes as creditsRoutes } from './sections/credits/routes'
import { routes as documentsRoutes } from './sections/documents/routes'
import { routes as donationsRoutes } from './sections/donations/routes'
import { routes as expensesRoutes } from './sections/expenses/routes'
import { routes as installmentsRoutes } from './sections/installments/routes'
import { routes as insuranceRoutes } from './sections/insurance/routes'
import { routes as limitRoutes } from './sections/limits/routes'
import { routes as overviewRoutes } from './sections/overview/routes'
import { routes as planChangeRoutes } from './sections/plan-change/routes'
import { routes as referralRoutes } from './sections/referral/routes'
import { routes as settingsRoutes } from './sections/settings/routes'
import { routes as shoppingRoutes } from './sections/shopping/routes'
import { routes as signupRoutes } from './sections/signup/routes'
import { routes as snapRoutes } from './sections/snap/routes'
import { routes as verificationRoutes } from './sections/verification/verification-cz-pl/routes'

const appRoutesDefinition = {
  auth: authRoutes,
  signup: signupRoutes,
  verification: {
    path: verificationRoutes.index.path,
    lastStep: {
      path: verificationRoutes.lastStep.path,
    },
  },
  overview: overviewRoutes,
  expenses: {
    path: expensesRoutes.overview.path,
  },
  shopping: {
    path: shoppingRoutes.overview.path,
  },
  card: cardRoutes,
  billing: billingRoutes,
  installments: installmentsRoutes,
  insurance: insuranceRoutes,
  cash: cashRoutes,
  settings: settingsRoutes,
  documents: documentsRoutes,
  referral: {
    path: referralRoutes.overview.path,
  },
  credits: {
    path: creditsRoutes.overview.path,
  },
  snap: {
    path: snapRoutes.overview.path,
  },
  donations: donationsRoutes,
  planChange: planChangeRoutes,
  limits: limitRoutes,
  notFound: '/404',
}

// in NextJS the section routes don't have `/app` prefix so we have to add it here
export const appRoutes = prependPathToRoutes('/app', appRoutesDefinition)
