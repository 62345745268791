import { assocPath, forEachObjIndexed, path, reduceBy, values } from 'ramda'
import type { FieldValues, UseFormReturn } from 'react-hook-form'

import { useChangeCallback } from '@twisto/hooks'
import type { AllUnionMemberKeys } from '@twisto/utils'

export type Maybe<T> = T | null

export type FormError = {
  field: string
  message: string
}

export type Errors<
  T extends Record<string, unknown | Record<string, unknown>>,
> = Record<
  keyof T,
  NonNullable<T[keyof T]> extends Record<string, unknown>
    ? Record<keyof NonNullable<T[keyof T]>, string>
    : string
>

type ErrorResponse = Maybe<{
  errors: Maybe<FormError[]>
}>

type FieldPath<
  T extends Record<string, unknown | Maybe<Record<string, unknown>>>,
> =
  | [keyof T]
  | readonly [keyof T]
  | [keyof T, AllUnionMemberKeys<T[keyof T]>]
  | readonly [keyof T, AllUnionMemberKeys<T[keyof T]>]

// for { field: 'a.b', message: ''}
// returns {
//   a: {
//     b: ['']
//   }
// }
const getResponseErrors = (dataResponse: ErrorResponse | undefined) => {
  if (!dataResponse?.errors) {
    return
  }

  const errors = dataResponse.errors

  return errors.reduce(
    (acc, errorMap) => {
      if (!errorMap.field) {
        return acc
      }

      const messages = [
        ...(path<string[]>(errorMap.field.split('.'), acc) ?? []),
        errorMap.message,
      ]

      return assocPath(errorMap.field.split('.'), messages, acc)
    },
    {} as Record<string, string[] | Record<string, string[]>>
  )
}

export const getFieldResponseErrors = <
  T extends Record<string, unknown | Maybe<Record<string, unknown>>>,
>(
  dataResponse: ErrorResponse | undefined,
  fieldPath: FieldPath<T>
) => {
  const errorsMap = getResponseErrors(dataResponse)

  return path<string[]>(fieldPath as string[], errorsMap)
}

export const getFieldResponseError = <
  T extends Record<string, unknown | Maybe<Record<string, unknown>>>,
>(
  dataResponse: ErrorResponse | undefined,
  fieldPath: FieldPath<T>
) => {
  const errorsMap = getResponseErrors(dataResponse)

  const errors = path<string[]>(fieldPath as string[], errorsMap)

  return errors ? errors[0] : undefined
}

export const getFirstResponseError = <
  T extends Maybe<{
    errors: Maybe<FormError[]>
  }>,
>(
  dataResponse?: T,
  includeNonFieldErrors = false
) => {
  if (!dataResponse?.errors) {
    return
  }

  const formFieldErrors = includeNonFieldErrors
    ? dataResponse.errors
    : dataResponse.errors.filter((error) => error.field !== 'nonFieldErrors')

  if (formFieldErrors.length) {
    return formFieldErrors[0].message
  }
}

type FieldName<T extends FieldValues> = Parameters<
  UseFormReturn<T>['setError']
>[0]

export const useResponseErrors = <T extends Record<string, unknown>>(
  dataResponse: ErrorResponse | undefined,
  formContext: UseFormReturn<T>
) => {
  useChangeCallback(() => {
    const errors = dataResponse?.errors?.filter(
      (error) => error.field !== 'nonFieldErrors'
    )

    if (errors) {
      const errorsMap = reduceBy(
        (acc, { message }) => ({
          ...acc,
          [`server-${values(acc).length}`]: message,
        }),
        {} as Record<string, string>,
        ({ field }) => field,
        errors
      )

      forEachObjIndexed(
        (messages, fieldName) =>
          formContext.setError(fieldName as FieldName<T>, { types: messages }),
        errorsMap
      )
    } else {
      formContext.clearErrors()
    }
  }, dataResponse)
}
