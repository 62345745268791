import { prependPathToRoutes } from '@twisto/utils'

export const basepath = '/limits'

const routesDefinition = {
  overview: {
    path: '/',
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)
