import { useAtom } from 'jotai'
import { equals } from 'ramda'

import type { FeatureFlagsQuery } from '@twisto/api-graphql'
import { useFeatureFlagsQuery } from '@twisto/api-graphql'
import { atomWithLocalStorage } from '@twisto/utils'

export type RemoteFeatureFlags = Record<
  keyof Omit<FeatureFlagsQuery['featureFlags'], '__typename'>,
  boolean
>

const defaultRemoteFeatureFlags: RemoteFeatureFlags = {
  installments: false,
  invoiceCategorization: false,
  ficus: false,
  cash: false,
  notifications: false,
  card: false,
  snap: false,
  travelInsurance: false,
  credits: false,
  planChange: false,
  savedCards: false,
  limit: false,
  split: false,
  saltEdgeBankAccounts: false,
  changeAddress: false,
  documents: false,
  // default here is true as having false there initially would result in redirect in verification app
  verification: true,
  inStore: false,
  referral: false,
  promoInVerification: false,
  directDebit: false,
  omnichannel: false,
}

export const remoteFeatureFlagsAtom = atomWithLocalStorage(
  'remoteFeatureFlags',
  defaultRemoteFeatureFlags
)

export const parseRemoteConfig = (
  featureFlags?: FeatureFlagsQuery['featureFlags']
) =>
  Object.entries(featureFlags ?? {}).reduce<RemoteFeatureFlags>(
    (acc, [key, value]) =>
      key === '__typename'
        ? acc
        : {
            ...acc,
            [key]: value === 'PILOT' || value === 'RELEASE',
          },
    defaultRemoteFeatureFlags
  )

export const RemoteFeatureFlagsLoader = () => {
  const [remoteFeatureFlags, setRemoteFeatureFlags] = useAtom(
    remoteFeatureFlagsAtom
  )

  useFeatureFlagsQuery({
    onCompleted: ({ featureFlags }) => {
      if (!equals(parseRemoteConfig(featureFlags), remoteFeatureFlags)) {
        setRemoteFeatureFlags(parseRemoteConfig(featureFlags))
      }
    },
  })

  return null
}
