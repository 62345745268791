import { useAtom } from 'jotai'
import { equals, isNil } from 'ramda'

import { useRemoteLanguageQuery } from '@twisto/api-graphql'
import { remoteLanguageAtom } from '@twisto/base/remote-language'

export const RemoteLanguageLoader = () => {
  const [store, setRemoteLanguage] = useAtom(remoteLanguageAtom)

  useRemoteLanguageQuery({
    onCompleted: (data) => {
      // If user is not logged in use the saved config instead of the one from the server
      if (!isNil(store) && isNil(data.account)) {
        return
      }
      if (!equals(store, data)) {
        setRemoteLanguage(data)
      }
    },
  })

  return null
}
