import { NextSeo } from 'next-seo'

import type { appRoutes } from 'customer/routes'

import { usePageTitleBySection } from './page-title-by-section.hook'

export type Section = keyof typeof appRoutes | 'notFound'

type Props = {
  section: Section
}

export const CustomerAppPageTitle = ({ section }: Props) => {
  const sectionName = usePageTitleBySection(section)
  if (!sectionName) {
    return null
  }

  return <NextSeo title={sectionName} />
}
