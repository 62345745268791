import { prependPathToRoutes } from '@twisto/utils'

const basepath = '/billing'

const routesDefinition = {
  dashboard: {
    path: '/*',
    getPath: () => '/' as const,
  },
  bill: {
    path: '/bill/:id/*',
    getPath: (id: string) => `/bill/${id}/` as const,
    payment: {
      path: '/bill/:billId/payment/:paymentId/*',
      getPath: ({ billId, paymentId }: { billId: string; paymentId: string }) =>
        `/bill/${billId}/payment/${paymentId}/` as const,
    },
  },
  dueBillsRedirect: {
    path: '/due-bills/',
  },
  deferral: {
    path: '/deferral/',
  },
} as const

export const routes = prependPathToRoutes(basepath, routesDefinition)
