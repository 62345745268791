import { useEffect } from 'react'

import { useAnalyticsIdsQuery } from '@twisto/api-graphql'
import { addGtmData } from '@twisto/base/gtm'

export const Analytics = () => {
  const { data } = useAnalyticsIdsQuery({
    context: {
      overrideAccessDeniedHandler: true,
    },
  })

  useEffect(() => {
    if (data?.account) {
      const plan =
        data.account.__typename === 'PremiumAccount'
          ? data.account.plan
          : data.account.__typename
      const name =
        data.account.__typename === 'PremiumAccount' ? data.account.name : ''
      addGtmData({
        plan,
        name,
        userId: data.account.id,
        email: data.account.email,
      })
    }
  }, [data])

  return null
}
