import { useEffect, useRef } from 'react'

export function usePrevious<T>(value: T, shouldUpdate?: boolean) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>()

  useEffect(() => {
    if (shouldUpdate ?? true) {
      // Store current value in ref
      ref.current = value
    }
  }, [shouldUpdate, value])

  // Return previous value (happens before update in useEffect above)
  return ref.current
}
