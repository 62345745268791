import type { HttpOptions } from '@apollo/client'
import { ApolloClient, ApolloLink, HttpLink, from } from '@apollo/client'
import type { ErrorResponse } from '@apollo/client/link/error'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'
import { isNil } from 'ramda'

import { networkStatusCode } from '@twisto/api-common'
import {
  AppError,
  parseApolloError,
} from '@twisto/components/molecules/error-boundary'
import { gitRevision, isDev } from '@twisto/environment'
import { getFieldResponseError } from '@twisto/form'
import { debug } from '@twisto/utils'

import { cache } from './cache'

type Context = {
  overrideGraphqlErrorHandler?: boolean
  overrideNonFieldErrorHandler?: boolean
}

export const globalErrorsHandler = ({
  error,
  setError,
  handleAccessDenied,
  handleGraphqlError,
}: {
  error: ErrorResponse
  handleAccessDenied?: (error: ErrorResponse) => void
  handleGraphqlError: () => void
  setError: (error: AppError) => void
}) => {
  const { graphQLErrors, response, networkError, operation } = error

  const context: Context = operation.getContext()
  // by providing `overrideGraphqlErrorHandler` in context, we can override the default error handler
  // this is useful for example when we want to handle errors in a different way
  const overrideGraphqlErrorHandler =
    context.overrideGraphqlErrorHandler ?? false

  // handle graphql errors by displaying default error modal with generic message
  // More about graphql errors here: https://www.apollographql.com/docs/react/data/error-handling/
  if (graphQLErrors && !overrideGraphqlErrorHandler) {
    // if BE sends 403, "permission denied" error, we handle it separately
    // by calling handleAccessDenied
    if (
      graphQLErrors[0]?.extensions.code === networkStatusCode.permissionDenied
    ) {
      handleAccessDenied?.(error)
    } else {
      // otherwise display default error modal
      // capture the errror in Sentry
      // and on dev, log the error to console
      handleGraphqlError()

      if (isDev) {
        // eslint-disable-next-line no-console
        console.warn(`
          GLOBAL ERROR!:
          ==============
          ${debug(response)}
          ==============
          ${debug(graphQLErrors)}
          ==============\n
        `)
      }

      Sentry.captureEvent({
        message: 'GraphQL error',
        level: 'error',
        extra: {
          response: debug(response),
          error: debug(graphQLErrors),
        },
      })
    }
  }

  if (networkError) {
    if (
      'statusCode' in networkError &&
      (networkError.statusCode === networkStatusCode.unauthorized ||
        networkError.statusCode === networkStatusCode.permissionDenied) &&
      handleAccessDenied
    ) {
      handleAccessDenied(error)
    } else {
      // check that the error isn't an intentional request abortion
      const context = error.operation.getContext()
      if (
        isNil(context.fetchOptions?.signal) ||
        error.networkError?.name !== 'AbortError'
      ) {
        setError(
          new AppError(networkError.message, {
            ...parseApolloError(error),
            payload: networkError,
          })
        )
      }
    }

    if (isDev) {
      // eslint-disable-next-line no-console
      console.warn(`[Network error]: ${debug(networkError)}`)
    } else if (
      'statusCode' in networkError &&
      networkError.statusCode >= networkStatusCode.internalServerError
    ) {
      Sentry.captureEvent({
        message: 'Network error',
        level: 'error',
        extra: {
          networkError,
        },
      })
    }
  }
}

export const setupApi = ({
  config,
  getHeaders,
  handleAccessDenied,
  handleGraphqlError,
  setError,
  resetPinLockTimer,
  showErrorWithContinue,
}: {
  config: HttpOptions
  getHeaders: () => Record<string, string>
  handleAccessDenied?: (error: ErrorResponse) => void
  handleGraphqlError: () => void
  setError: (error: AppError) => void
  resetPinLockTimer?: () => void
  showErrorWithContinue: (message: string) => void
}) => {
  const nonFieldErrorHandlerLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: getHeaders(),
    })

    return forward(operation).map((result) => {
      resetPinLockTimer?.()

      if (!result.data) {
        return result
      }

      const context: Context = operation.getContext()
      const overrideNonFieldErrorHandler =
        context.overrideNonFieldErrorHandler ?? false

      const nonFieldErrors = Object.values(result.data)
        .map((data) => getFieldResponseError(data, ['nonFieldErrors']))
        .filter((errorObject) => !!(errorObject ?? ''))

      if (!isNil(nonFieldErrors[0]) && !overrideNonFieldErrorHandler) {
        showErrorWithContinue(nonFieldErrors[0])
      }

      return result
    })
  })

  const globalErrorsHandlerLink = onError((error) =>
    globalErrorsHandler({
      error,
      setError,
      handleGraphqlError,
      handleAccessDenied,
    })
  )

  const httpLink = new HttpLink(config)

  const client = new ApolloClient({
    link: from([
      nonFieldErrorHandlerLink,
      globalErrorsHandlerLink,
      // keep the terminating link at the end of the chain
      httpLink,
    ]),
    cache,
    assumeImmutableResults: true,
    // https://www.apollographql.com/docs/graphos/metrics/client-awareness/#setup
    name: 'web-customer',
    version: `v.${gitRevision}`,
  })

  return client
}
