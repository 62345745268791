import type { ReactNode } from 'react'

import { BaseApp } from '@twisto/components/organisms/base-app'

import { Analytics } from 'customer/components/analytics'
import { CustomerClientProvider } from 'customer/components/customer-app/customer-client-provider'
import type { Section } from 'customer/components/customer-app-page-title'
import { CustomerAppPageTitle } from 'customer/components/customer-app-page-title'
import { PinLockTimerProvider } from 'customer/components/timeout-modal'

import {
  RemoteFeatureFlagsLoader,
  RemoteLanguageLoader,
} from '../customer-config'

type Props = {
  children: ReactNode
  pinLockEnabled?: boolean
  section: Section
}

export const CustomerApp = ({
  children,
  pinLockEnabled = true,
  section,
}: Props) => (
  <BaseApp>
    <CustomerAppPageTitle section={section} />
    <PinLockTimerProvider pinLockEnabled={pinLockEnabled}>
      <CustomerClientProvider>
        <Analytics />
        <RemoteLanguageLoader />
        <RemoteFeatureFlagsLoader />
        {children}
      </CustomerClientProvider>
    </PinLockTimerProvider>
  </BaseApp>
)
